/**
 * Combined by jsDelivr.
 * Original files:
 * - /npm/vue@3.2.33/dist/vue.global.prod.min.js
 * - /npm/bootstrap@5.1.3/dist/js/bootstrap.bundle.min.js
 * - /npm/php-date-formatter@1.3.6/js/php-date-formatter.min.js
 * - /npm/@sentry/vue@6.19.3/build/bundle.vue.min.js
 * - /npm/@sentry/tracing@6.19.7/build/bundle.tracing.min.js
 *
 * Do NOT use SRI with dynamically generated files! More information: https://www.jsdelivr.com/using-sri-with-dynamic-files
 */
